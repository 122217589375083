@import '../modules/navbar';

.global {
  @include navbar;

  .left > a {
    display: inline-block;
  }
}

.signoff-nav {
  background: linear-gradient(to right, $teal-velvet, $brilliant-sky);
  color: #ffffff;
}

nav.main-nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

nav.main-nav .left, nav.main-nav .right {
  float: none;
  display: flex;
  flex-flow: row nowrap;
}

/**** Header Logo ****/
nav a.logo {
  display: block;
  margin-right: 1 rem;

    img {
      height: 2rem;
      margin: 0.25rem;
    }
}

/**** Main Menu ****/
nav ul.main-menu {
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  margin: 0; padding: 0;
  gap: 1rem;
  height: 2.5rem;
  align-items:baseline;
  li {
    display: block;
    margin:0; padding: 0rem;
    i {margin-right: 0.25rem;}
    span.toplevel {
        display: block;
        padding: 0.5rem;
        color: $brilliant-sky;
      }

    ul {
      display: none;
      flex-flow: column;
      z-index: 50;
      margin:0; padding:0;
      background: #fff;
      border-left: 3px solid $brilliant-sky;
      box-shadow: 2px 2px 6px #777777;


      li {
        display: block;
        margin:0; padding: 0;

        a {
          display: block; padding: 0.5rem 0.8rem;
          span {font-size:1rem; vertical-align: middle;}
        }
      }
    }
  }
}


#menutrigger {display: none;}
#menutrigger:checked + ul.main-menu li ul {
  display: block;
}

/**** Logout/Account Menu ****/
nav.main-nav .right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  a {display: block; padding: 0.25rem; vertical-align: middle;}
}

.mini-user {
  font-size: 12pt;
  line-height: 12pt;
  padding: 0.5rem;
  vertical-align:middle;
}


/***** side menu *****/
.toggled {
  display: inline-block;
  transition: 0.3s ease-in-out;
  transform: rotate(90deg);
}

.menu-nested-list{
  margin-left: 0;
}

.menu-toggle-list {
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 0;
  md-list-item .md-no-style {
    padding-left: 0;
  }
}

ul.side-menu {
  margin-left: 0;
}

md-list-item .menu-icon {
  width: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
}
