@import '../modules/title-bar';

.title {
  @include title-bar;

  &.phone {
    font-size: 1em;
    padding: .35em .5em;
  }

}

.group-title {
  background-color: $white;
  color: $teal-velvet;
  font-weight: bold;
  margin-top: 0.8em;
  padding:0.5em 0 0.3em 0;
  text-align: center;
}

.back-to-form {
  font-size: .8em;
  margin-top: -2em;
  padding-bottom: .2em;
  padding-right: 1em;
  text-align: right;
}
