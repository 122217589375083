@import '../settings';

$nav-bar-height: 2.5rem;
$nav-bar-padding: .5em;

/// The bar that contains the icons for navigating to the dashboard
/// and leaving the application.
@mixin navbar {
  background: $white;
  display: block;
  font-size: 1.4em;
  height: $nav-bar-height;
  left: 0;
  line-height: $nav-bar-height;
  overflow: visible;
  padding: 0 $nav-bar-padding;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 40;
}
