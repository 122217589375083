@import '../settings';

$action-btn-size: 3em;

@mixin action-tray {
  bottom: 0;
  left: 0;
  padding: 1em;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 58;

  > * {
    pointer-events: auto;
  }

  .action-btn {
    @include shadowed;
    background: $white;
    border-radius: 50%;
    display: block;
    height: $action-btn-size;
    line-height: $action-btn-size;
    text-align: center;
    width: $action-btn-size;
  }
}

