@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?7wbg0l') format('truetype'),
    url('fonts/icomoon.woff?7wbg0l') format('woff'),
    url('fonts/icomoon.svg?7wbg0l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Frailty-10-NG:before {
  content: "\e90c";
}
.icon-Frailty-01-NG:before {
  content: "\e900";
}
.icon-Frailty-02-NG:before {
  content: "\e901";
}
.icon-Frailty-03-NG:before {
  content: "\e902";
}
.icon-Frailty-04-NG:before {
  content: "\e903";
}
.icon-Frailty-05-NG:before {
  content: "\e904";
}
.icon-Frailty-06-NG:before {
  content: "\e905";
}
.icon-Frailty-07-NG:before {
  content: "\e906";
}
.icon-Frailty-08-NG:before {
  content: "\e907";
}
.icon-Frailty-09-NG:before {
  content: "\e908";
}
.icon-uniE606:before {
  content: "\e606";
}
.icon-uniE607:before {
  content: "\e607";
}
.icon-uniE608:before {
  content: "\e608";
}
.icon-uniE609:before {
  content: "\e609";
}
.icon-uniE60A:before {
  content: "\e60a";
}
.icon-uniE60B:before {
  content: "\e60b";
}
.icon-uniE60C:before {
  content: "\e60c";
}
.icon-uniE60D:before {
  content: "\e60d";
}
.icon-uniE60E:before {
  content: "\e60e";
}
.icon-uniE60F:before {
  content: "\e60f";
}
.icon-uniE610:before {
  content: "\e610";
}
.icon-uniE611:before {
  content: "\e611";
}
.icon-Toddler-NoGender:before {
  content: "\e909";
}
.icon-Child-NoGender:before {
  content: "\e90a";
}
.icon-Baby-NoGender:before {
  content: "\e90b";
}
