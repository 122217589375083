@import '../settings';

.patient.data {
  @include overflow;
  background-color: $white;
  padding-bottom: 0;
  padding-top: 0;
  --gap-space: 1em;
  .row {
    height: 10em;
    margin-bottom: .2em;
  }

  .fill {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-space);
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  .shepherd-container {
    position: absolute;
    width: 66%;
    height: 100%;
    left:0;
    z-index:-1
  }

  .third {
    min-width: 100px;
    flex-basis: calc( 33.3% - var(--gap-space));
    flex-grow: 1;
    flex-shrink: 1;
    display: inline-block;
    //padding: .1em;
    vertical-align: top;
  }

  .item {
    //@include panel;
    background: $white;
    min-height: 9em;
    padding: 1em 0 0 0;
    text-align: center;

    span.non-radial {
      display: block;
      margin-top: 1em;
    }
  }
}
