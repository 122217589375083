@import '../settings';


/// @name search
/// Patient Search UI


$theme: #2ba6cc;
$transparent-grey: #ddd;
$light-pink: #fbe3e3;



/// Video Search UI
fieldset {
    border: 1px solid white;
    padding: 1rem;

    legend {
        color: $striking-cyan;
        font-size: 1.2rem;
    }
}

.searchbar {
    background-color: $pale-cyan;

    label { font-size: 1.2rem;
            color: $striking-cyan;}
    .searchinput {display: flex; flex-flow:row; justify-content: space-between;}            
    input[type="text"]{
            border: 2px solid $strong-cyan;
            margin-top: 1em;
            height: 3rem;
            flex-grow:1;
        }    
}

button.searchbar {
    background-color: $striking-cyan;
    color: $white;
    margin: 1em 0 0 1em;
    height: 3rem;
    flex-grow:0;
  }

@media screen and (max-width: 400px) {   
    .searchbar {
        label { font-size: 1.1rem;}
        .searchinput {margin-top: 1em; }
        input[type="text"]{
                border: 2px solid $strong-cyan;
                margin: 0em 0.5em 0 0;
                height: 2rem;
                width: 100%
            }    
    }
    button.searchbar {
        height: 2rem;
        margin:0;
        padding: 0.5em;
        width:7rem;
      }
}


// General Chips

fieldset.chips {
    padding: 0.5rem;
    width: auto;
    max-height: 25em; 
    overflow-x: hidden; 
	}
.chips legend { 
    width:100%;
    padding-top:1em; padding-bottom: 0.5em;
    span.taginfo {font-size: 0.8em; font-style: italic; color: #666; display: block;}
}

input.chip[type="checkbox"] {
display: none;
}

input.chip + label {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.8rem;
	cursor: pointer;
	border-radius: 1.5rem;
	background-color: #B7DAE0;
	border:solid 2px #B8DAE0;
	padding: 0.4rem 1rem;
	margin: 0 0.6rem 0.6rem 0;
	font-weight: normal;
	display: inline-block;
	-moz-user-select: -moz-none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}                  

input.chip:checked + label, input.chip:checked + label.topic, input.chip:checked + label.tag  {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	background-color: #44B0D1; color: white; border-color:#45B0D1;
}    
input.chip + label em {
	font-size:0.75rem;
}                           

// Topic Chips    
input.chip + label.topic {
    background-color: #fff;
}
input.chip + label.topic:hover {
    background-color: $pale-cyan; color: black;
}

// Tag Chips    
input.chip + label.tag {
    font-size: 0.75rem;
	padding: 0.1rem 0.4rem;
	border-radius: 0.7rem;
    background-color: #cdf3f7;
    border-color: $pale-cyan;
}
input.chip + label.tag:hover {
    background-color: white; color: black;
}

// List Video Results
.videolist {
    color: #444;
    font-size: 1.1rem;
    line-height: 1.3rem;
    display:flex;
    flex-flow: row wrap;
    align-content: space-between;
    width: 100%
}
@media screen and (max-width: 400px) {
  .videolist .md-headline {
    flex-direction: column;
  }
}


.videolist .md-headline {
    color: #444;
    font-size: 1.1rem;
    line-height: 1.3rem;
    }

.videolist h3 {
    color: $strong-cyan;
    font-size: 1.3rem;
}

// Display Vanilla (i.e. youtube) vids with plain img thumbnail inside a card

md-card.vanillavid {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis:230px;
    align-self: flex-start;
    min-height: 250px;
    }
@media (max-width: 400px) {
    md-card.vanillavid {
        flex-grow: 1;
        flex-basis:100px;
        }
}
md-card.vanillavid img {
    width: 100%;
    height:auto;
    min-height: 150px;
    background: #f1edf7;
}

// Display Caramel (i.e. vimeo) vids with plain embedded video inside a card
md-card.caramelvid {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis:230px;
    align-self: flex-start;
    min-height: 230px;
    
    md-card-title { max-width: 235px;}
}
md-card.caramelvid .overlay {
    display:block; 
    width:235px; 
    height: 145px;
    z-index: 2; 
    margin: 0; padding: 0;  
    border: 1px solid white;
}
md-card.caramelvid .embed{
    z-index: 1; position:absolute; border: 1px solid white
}
md-card.caramelvid .embed iframe {
    width: 230px; height:130px
}

@media screen and (max-width: 400px) {
    md-card.caramelvid {
        flex-grow:1;
        flex-shrink:0;
        md-card-title { width: auto;}
    }
    md-card.caramelvid .overlay {
        width:350px; 
        height: 200px;
        border:1px solid #fff;
    }
    md-card.caramelvid .embed{
        border:1px solid #fff;
    }
    md-card.caramelvid .embed iframe {       
        width:348px; height: 195px;
    }   
}


// Display film panel

.filmpanel {
    padding: 1em; 
    color: #666;

    h2.filmtitle {font-size: 1.3em; color: $striking-cyan;}

    iframe{
        width: 800px; 
        height: 450px;
        margin: 0;
    }
    
    .infopanel {
        display: flex; 
        flex-flow: column; 
        justify-content: space-between;  
        width: 100%; max-width: 800px;

        div {margin: 1em 0;}
        
        
        p {font-size: 1em;}
        h3 {font-size: 1.1em; color: $striking-cyan;}
        span.taginfo {font-size: 0.8em; font-style: italic; color: #666;}

        div.taglist {

            fieldset.chips {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                gap: 10px
            }

            button.chip {
                padding: 0.3rem 0.5rem; margin: 0;
                font-size: 0.8em; font-weight: 500;
                text-transform: capitalize;
                border-radius: 0.7em;
                border: none;
                color: #012a3d;
                background-color: #d8e8ed;
                }

            button.chip:hover {
                background-color: $strong-cyan;
                color: #fff;
                }

        }

    }    
}

@media screen and (max-width:400px) {
    .filmpanel {    
        iframe{
            width: 250px; 
            height: 141px;
        }
        .infopanel {max-width: 360px;}
    }
}